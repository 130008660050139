<!-- 资金密码 -->
<template>
    <div class="viewsPages">
        <div class="UserInfo-title">{{$t("userPage.text203")}}</div>
        <div class="views-FundPass">
            <!-- <div class="views-title">{{$t("userPage.text197")}}</div>
            <div class="views-title-small">
                <div @click="_jump('userInfo')" class="cursorPointer">{{$t("userPage.text197")}}</div>
                <i class="views-title-icon"></i>
                <div>{{$t("userPage.text203")}}</div>
            </div> -->
            <Form
                :model="form"
                :rules="rules"
                class="FundPass-form"
                ref="FundPass"
                label-width="150px">
                <!-- 旧密码 -->
                <Form-item :label='$t("userPage.text204")+"："' prop="oldPass" v-if="userData.withdrawPassWordState">
                    <Input class="fund-item-input" show-password :placeholder='$t("userPage.text205")' v-model="form.oldPass" />
                    <span>{{$t("userPage.text206")}}</span>
                </Form-item>
                <!-- 新密码 -->
                <Form-item :label='$t("userPage.text207")+"："' prop="newPass">
                    <Input class="fund-item-input" show-password :placeholder='$t("userPage.text208")' v-model="form.newPass" />
                    <span>{{$t("userPage.text209")}}</span>
                </Form-item>
                <!-- 确认密码 -->
                <Form-item :label='$t("userPage.text210")+"："' prop="confirmPass">
                    <Input class="fund-item-input" show-password :placeholder='$t("userPage.text211")' v-model="form.confirmPass" />
                    <span>{{$t("userPage.text212")}}</span>
                </Form-item>
                <div class="FundPassBtn">
                    <Button class="FundPass-btn" @click="modify('FundPass')">{{$t("userPage.text213")}}</Button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Input, Button,Form, FormItem } from 'element-ui'
import { _jump } from '@/core/utils/utils'
import { _modifyFundPass } from '../../../core/comp/pages/userInfo'
export default {
    components: {
        Input,
        Button,
        Form,
        FormItem,
    },
    data () {
        const confirmPass = (rule, value, callback) =>{
            if(!value) {
                return callback(new Error(this.$t("userPage.text214")))
            }else{
                if(!/^[0-9]{6}$/.test(value)) return callback(new Error(this.$t("userPage.text215")))
                if(value != this.form.newPass) return callback(new Error(this.$t("userPage.text216")))                              
            }
            callback();
        }
        const tradePasswordIsValid = (rule, value, callback) => {
            //交易密码校验
            if(!value) {
                return callback(new Error(this.$t("userPage.text217")))
            }else{
                if(!/^[0-9]{6}$/.test(value)) return callback(new Error(this.$t("userPage.text218")))
            }
            callback();
        }
        return {
            form: {
                oldPass: '',
                newPass: '',
                confirmPass: '',
            },
            rules:{
                oldPass:[{required:true,message:this.$t("userPage.text219"),trigger: "blur"}],
                newPass:[{required:true,validator:tradePasswordIsValid,trigger: "blur"}],
                confirmPass:[{required:true,validator:confirmPass,trigger: "blur"}],
            },
        }
    },
    computed: {
        ...mapGetters([
            'userData'
        ])
    },
    methods: {
        _jump,
        // 修改资金密码
        modify (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _modifyFundPass(
                        this.form,
                        () => { // 修改成功
                        this.form = {
                            oldPass: '',
                            newPass: '',
                            confirmPass: '',
                        }
                        this.$router.push({name:'userInfo'})
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss">
$imgUrl: '~@/assets/images/my/';
.views-FundPass{
    width: 890px;
    margin: 20px auto;
    padding: 20px 40px;
    border-radius: 10px;
    background:#DEDEDE;
    .FundPass-form{
        margin-top: 40px;
        .el-form-item {
            .el-form-item__label {
                color: #8E8E8E;
            }
            .el-form-item__content{
                display: flex;
                align-items: center;
            }
            .el-input {
                width: 260px;
                margin-right: 20px;
                input{
                    width: 260px;
                    height: 50px;
                    line-height:50px;
                    padding: 0 15px;
                    border-radius: 6px;
                    color: #8E8E8E;
                    background:#fff;
                    border: 1px solid #ccc;
                    &::-webkit-input-placeholder{
                        color:#888888;
                    }
                }
            }
        }
        .FundPassBtn{
            margin-top: 80px;
            text-align: center;
            .FundPass-btn{
                color: #fff;
                border:none;
                height: 40px;
                text-align: center;
                font-size: 18px;
                background: #ce06cd;
                width: 45%;
                border-radius: 10px;
                margin: 0 auto;
            }
        }
    }
}
</style>